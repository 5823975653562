// src/App.js
import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [ipInput, setIpInput] = useState('');
  const [ipScore, setIpScore] = useState(null);
  const [ipMap, setIpMap] = useState(new Map());
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(null); // To store the result in a structured format

  const ipRegex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  useEffect(() => {
    const loadBlacklists = async () => {
      const tempMap = new Map();

      for (let level = 8; level >= 1; level--) {
        try {
          const response = await fetch(
            `https://raw.githubusercontent.com/stamparm/ipsum/master/levels/${level}.txt`
          );
          const text = await response.text();
          const ips = text.split('\n').filter(ip => ip.trim());

          ips.forEach(ip => {
            if (!tempMap.has(ip)) {
              tempMap.set(ip, level);
            }
          });
        } catch (error) {
          console.error(`Error loading level ${level}:`, error);
        }
      }

      setIpMap(tempMap);
      setIsLoading(false);
    };

    loadBlacklists();
  }, []);

  const checkIP = (ip) => {
    if (!ipRegex.test(ip)) {
      setResult({
        isValid: false,
        ip,
      });
      setIpScore(null);
    } else {
      const score = ipMap.get(ip) || 0;
      let adjustedScore;
      
      switch (score) {
        case 0:
          adjustedScore = 0;
          break;
        case 1:
          adjustedScore = 50;
          break;
        case 2:
        case 3:
          adjustedScore = 90;
          break;
        case 4:
        case 5:
        case 6:
          adjustedScore = 95;
          break;
        case 7:
        case 8:
          adjustedScore = 100;
          break;
        default:
          adjustedScore = 0;
      }
    
      const judgement = getScoreText(adjustedScore);
      setResult({
        isValid: true,
        ip,
        score: adjustedScore,
        judgement,
      });
      setIpScore(adjustedScore);
    }
  };

  // const checkIP = (ip) => {
  //   if (!ipRegex.test(ip)) {
  //     setResult({
  //       isValid: false,
  //       ip,
  //     });
  //     setIpScore(null);
  //     return;
  //   }
  //   const score = ipMap.get(ip) || 0;
  //   const judgement = getScoreText(score);
  //   setResult({
  //     isValid: true,
  //     ip,
  //     score,
  //     judgement,
  //   });
  //   setIpScore(score);
  // };

  function getScoreText(score) {
    if (score === 0) return 'SAFE';
    if (score <= 50) return 'SUSPICIOUS';
    return 'MALICIOUS';
  }

  const getScoreColor = () => {
    if (ipScore === 0) return 'green';
    if (ipScore <= 50) return 'yellow';
    if (ipScore > 50) return 'red';
    return 'orange'; // Default for invalid IP
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    checkIP(ipInput.trim());
  };

  return (
    <div className="app">
      <h1>IP Score</h1>
      <p>Powered by Open Source Threat Intelligence</p>
      {isLoading ? (
        <div className="loading">Loading IP Models...</div>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              value={ipInput}
              onChange={(e) => setIpInput(e.target.value)}
              placeholder="Enter IP address"
              required
            />
            <button type="submit">Check IP</button>
          </form>

          {result && (
            result.isValid ? (
              <table className="display-table">
                <tbody>
                  <tr>
                    <td>IP Address</td>
                    <td>{result.ip}</td>
                  </tr>
                  <tr>
                    <td>Score</td>
                    <td style={{ color: getScoreColor() }}>
                      {`${result.score}`}
                    </td>
                  </tr>
                  <tr>
                    <td>Judgement</td>
                    <td style={{ fontWeight: 'bold', color: getScoreColor() }}>
                      {result.judgement}
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div className="invalid-message" style={{ color: 'red' }}>
                The value <strong>{result.ip}</strong> is not a valid IP.
              </div>
            )
          )}
        </>
      )}
    </div>
  );
}

export default App;
